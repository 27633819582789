<template>
	<div v-if="dialogVisible" :class="isdis?'disbox':''">
		<el-dialog title="稿件详情" :visible.sync="dialogVisible" width="40%" :before-close="resetclose">
			<div class="addbox" ref="printbox">
				<div class="left_info" style="height: 70vh;overflow-y: auto;">
					<div style="width: 100%;text-align: center;font-size: 24px;margin-bottom: 20px;">
						{{info.name}}
					</div>
					<div style="width: 100%;text-align: center;margin-bottom: 10px;font-size:22px;">
						{{info.hbr}}
					</div>
					
					<div style="width: 100%;" class="myhtml" v-html="info.content">
						
					</div>
					<div style="width: 100%;text-align: right;margin-bottom: 10px;margin-top: 20px;">
						{{info.addtime}}
					</div>
					<!-- <div style="width: 100%;margin-bottom: 10px;">
						截止时间：{{info.endtime}}
					</div>
					<div style="width: 100%;display: flex;">
						<div>附件列表：</div>
						<div style="width: 80%;">
							<div v-for="(item2,index2) in info.fujian" :key="index2" style="color: #409EFF;margin-bottom: 5px;cursor: pointer;width: 80%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="open(item2.fullurl)">
								{{item2.filename}}
							</div>
						</div>
					</div> -->
				</div>
			</div>
			<div class="btnbox">
				<el-button type="" size="mini" class="font14" @click="resetclose">关闭</el-button>
				<el-button v-show="showprint" type="success" size="mini" @click="print">打 印</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	import { asBlob } from 'html-docx-js/dist/html-docx';
	import { saveAs } from 'file-saver';
	import { Loading, Message } from "element-ui";
	export default {
		props:{
			showprint:{
				type:Boolean,
				defaut:false
			}
		},
		data() {
			return {
				dialogVisible: false,
				info:'',
				isdis:false,//是否渲染dom，但是页面上不显示  用于下载的
				loadingS:''
			}
		},
		methods: {
			exportToWord() {				
				this.loadingS = Loading.service({
                   lock: true,
                   text:"处理中...",
                   spinner: 'el-icon-loading',
                   background: 'rgba(0, 0, 0, 0.7)'
               });
				this.$nextTick(()=>{
					const content = this.$refs.printbox.innerHTML;
					const converted = asBlob(content);
					saveAs(converted, this.info.hbr+'-'+this.info.name+'.docx');
					this.loadingS.close();
					this.resetclose()
				})
			    
			},
			open(url){
				window.open(url,'_blank')
			},
			print(){
				this.$printPage(this.$refs.printbox)
			},
			resetclose(){				
				this.isdis = false
				this.dialogVisible=false
				this.loadingS = ''
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;

	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	
	.disbox{
		z-index: -10;
		opacity: 0;
	}
</style>